* {
  box-sizing: border-box
}

*:before, *:after {
  box-sizing: border-box
}

.splash-screen-container {
  position: fixed;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  display: flex;
}

.splash-screen {
  margin: auto;
}

.brand {
  width: 800px;
}

.brand h1 {
  max-width: 500px;
}

.header-separator {
  margin-bottom: 15px;
}

.nav-services a {
  cursor: pointer;
}

.width100 {
  width: 100% !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-15 {
  margin-top: 15px !important;
}

.mt-50 {
  margin-top: 50px !important;
}

.ml-0 {
  margin-left: 0 !important;
}

.ml-15 {
  margin-left: 15px !important;
}

.mr-15 {
  margin-right: 15px !important;
}

.pl-0 {
  padding-left: 0 !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-5 {
  margin-bottom: 5px !important;
}

.mb-15 {
  margin-bottom: 15px !important;
}

.line-break {
  white-space: pre-wrap;
}

.gray {
  color: gray;
}

.green {
  color: green;
}

.red {
  color: red;
}

.tam-alert {
  margin-bottom: 22px;
  line-height: 1.6;
  font-size: 14px;
}

.mat-tooltip {
  font-size: 14px !important;
}

.required {
  padding-right: 0;
}

.required::after {
  content: ' *';
}

.clear-input-button {
  position: absolute;
  right: 15px;
  cursor: pointer;
  margin: 3px;
  padding: 1px 5px;
  display: block;
  font-size: 0.9em;
}

.clear-input-button:after {
  content: '\d7';
}

.clear-input-button:hover {
  color: #d0021b;
}

@media(min-width:768px){
  .padding-left-md-10 {
    padding-left: 10px;
  }
}

:focus {
  outline: 0!important;
}

ul.nav li {
  cursor: pointer;
}

div.admin {
  margin-bottom: 5px;
}

div.admin button {
  margin-top: 0;
}

p.admin {
  margin: 10px 0 0 0;
  font-size: 0.9em;
}

p.admin-warning {
  margin: 10px 0 0 0;
  font-size: 1.0em;
  color: #dc0018;
}

p.admin-success {
  margin: 10px 0 0 0;
  font-size: 1.0em;
  color: #118811;
}

input.admin-settings {
  width: 100%;
  height: 30px;
  font-size: 14px;
  margin-top: 0;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid gainsboro;
}

textarea.admin-settings {
  width: 100%;
  height: 90px;
  resize: vertical;
  font-size: 14px;
  margin-top: 0;
  padding-left: 5px;
  padding-right: 5px;
  border: 1px solid gainsboro;
}

p.admin-settings {
  margin: 0;
  font-size: 14px;
}

p.admin-checks {
  margin: 5px 0;
  font-size: 14px;
}

button.admin-settings {
  margin-right: 4px;
  margin-bottom: 2px;
}

div.row.admin {
  margin-top: 10px;
}

.cursor-pointer {
  cursor: pointer;
}

.hover-red:hover {
  color: #DC0018;
}

.highlight-new {
  color: #E6B800;
}

.highlight-copy {
  color: #0000CC;
}

.highlight-empty {
  color: #CCCCCC;
}

.dokument-table {
  text-align: left;
  border-spacing: 15px 0;
  border-collapse: separate;
}

.dokument-filter {
  border-spacing: 15px 0;
  border-collapse: separate;
}

.dokument-filter tr td:first-child {
  text-align: left;
}

.meldung-list-icon {
  padding: 0.45em 0;
  line-height: 1.2;
}

.btn-delete {
  color: #fff;
  background-color: #d9534f;
  border-color: #d9534f;
}

.btn-delete:hover, .btn-delete:focus {
  color: #fff;
  background-color: #cc4e49;
  border-color: #cc4e49;
}

.btn-delete:active {
  color: #fff;
  background-color: #b34440;
  border-color: #b34440;
}

@media (min-width:1250px){
  .container{
    width: 1200px;
  }
}

/* Tooltips benötigen einen höheren Z-Index als Swal */
.cdk-overlay-container {
  z-index: 2000 !important;
}

/* User-Name oben anzeigen (übernommen von nav-lang) */
.nav-user {
  display: inline-block;
  float: right;
  margin-right: 15px;
  margin-bottom: 15px;
  padding-right: 15px
}

@media only screen and (min-width: 992px) {
  .nav-user {
    border-right: 1px solid #E5E5E5
  }
}

@media only screen and (max-width: 767px) {
  .nav-user {
    margin-bottom: 0;
    margin-right: 5px;
    padding-right: 0
  }
}

.nav-user li {
  padding: 0;
  margin: 0
}
